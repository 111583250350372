// @flow

import { useSelector } from 'react-redux';
import { selectStoreCode } from 'shared_services/redux/selectors/storeCode';

/**
 * useStoreCode
 *
 * @returns {?string}
 */
export const useStoreCode = (): ?string => {
  return useSelector(selectStoreCode);
};
