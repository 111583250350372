// @flow

import { Component } from 'react';
import { connect } from 'react-redux';
import { toggleAttachComponent } from 'shared_services/redux/actions/application/pagepreload';
import { areDifferent } from 'shared_services/riseart/utils/Utils';

import type { Node } from 'react';

type Props = {
  attach?: boolean,
  uniqueId: string,
  actionToggleAttach: Function,
  children: Node,
};

/**
 * PreloaderPage
 *
 * Usage: <DataProviderPreloaderPage uniqueId={this.loaderUniqueId} attach={this.state.attachToLoader} />
 * attach = true // component will dispatch an action and it will shows the preloader
 * attach = false //  the preloader will be removed
 * uniqueId =  unique code for attach/detach ( don't use the same id more than 1 time for action attach/detach! )
 */
class PreloaderPage extends Component<Props> {
  static defaultProps = { attach: false, detach: false };

  /**
   * componentDidUpdate
   * @param {Props} prevProps
   */
  componentDidUpdate(prevProps: Props) {
    if (!areDifferent(this.props.attach, prevProps.attach)) {
      return;
    }

    const { uniqueId, attach, actionToggleAttach } = this.props;
    const id = uniqueId || prevProps.uniqueId;

    actionToggleAttach(id, attach);
  }

  /**
   * ComponentDidMount
   */
  componentDidMount() {
    const { attach: shouldAttach, uniqueId, actionToggleAttach } = this.props;

    if (shouldAttach === true) {
      actionToggleAttach(uniqueId, shouldAttach);
    }
  }

  /**
   * componentWillUnmount
   */
  componentWillUnmount() {
    const SHOULD_ATTACH = false;
    this.props.actionToggleAttach(this.props.uniqueId, SHOULD_ATTACH);
  }

  /**
   * render
   */
  render() {
    return this.props.children || null;
  }
}

/**
 * mapDispatchToProps
 * @param dispatch
 * @returns {Object}
 */
function mapDispatchToProps(dispatch: Function): Object {
  return {
    actionToggleAttach: (id, shouldAttach = true) => {
      dispatch(toggleAttachComponent(id, shouldAttach));
    },
  };
}

export const DataProviderPreloaderPage = connect<*, *, *, *, *, *>(
  null,
  mapDispatchToProps,
)(PreloaderPage);
