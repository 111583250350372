// @flow

import { useQuery as useApolloQuery } from '@apollo/client';

/**
 * useQuery
 *
 * A wrapper for the base Apollo useQuery hook
 * The main purpose is to keep the state of previousData while the new data is loaded which was changed in apollo client v3
 * https://www.apollographql.com/docs/react/migrating/apollo-client-3-migration/#apolloreact-hooks
 *
 * @param {Object} query
 * @param {Object} options
 * @returns {Object}
 */
export function useQuery(query: Object, options?: Object): Object {
  const { data, previousData, loading, ...queryRestProperties } = useApolloQuery(query, options);

  return {
    ...queryRestProperties,
    previousData,
    loading,
    rawData: data,
    data: loading && !data ? previousData : data,
  };
}
